const cardResizeObserver = new ResizeObserver((entries) => {
  entries.forEach((entry) => {
    const contentBoxSize: ResizeObserverSize = Array.isArray(
      entry.contentBoxSize,
    )
      ? entry.contentBoxSize[0]
      : entry.contentBoxSize;

    (entry.target as HTMLElement).style.setProperty(
      '--card-width',
      `${contentBoxSize.inlineSize}px`,
    );
  });
});

document.querySelectorAll<HTMLElement>('.card').forEach(($card) => {
  cardResizeObserver.observe($card);
});
