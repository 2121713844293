import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { on } from 'delegated-events';
import { focusable } from 'tabbable';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import moveFocus from '../../../javascripts/utils/moveFocus';
import elementIndex from '../../../javascripts/utils/elementIndex';

const getTrackingCategory = ($element: HTMLElement) => {
  const $base = $element.closest<HTMLElement>('[data-menu-tracking-category]');
  return $base?.dataset.menuTrackingCategory ?? 'menu';
};

// Navigation
const switchMenu = ($oldMenulink: HTMLElement, $newMenulink: HTMLElement) => {
  // Focus to new tab
  invisibleFocus($newMenulink);

  // Set the selected state
  $newMenulink.setAttribute('aria-expanded', 'true');
  $oldMenulink.removeAttribute('aria-expanded');

  // Hide old panel
  const oldTabId = $oldMenulink.getAttribute('aria-controls');
  if (oldTabId) {
    const $oldPanel = document.getElementById(oldTabId);
    $oldPanel?.setAttribute('aria-hidden', 'true');
  }

  // Show new panel
  const newTabId = $newMenulink.getAttribute('aria-controls');
  if (newTabId) {
    const $newPanel = document.getElementById(newTabId);
    $newPanel?.setAttribute('aria-hidden', 'false');
  }

  // Send tracking information
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'nav_menu_interaction',
    eventCategory: getTrackingCategory($newMenulink),
    eventSubcategory: 'tab_switch',
    eventSubcategory2: $oldMenulink.textContent,
    eventSubcategory3: 'level_1',
    eventLocation: 'text_link',
    eventInnerLocation: $oldMenulink.parentElement
      ? (elementIndex($oldMenulink.parentElement) + 1).toString()
      : undefined,
    eventLabel: $newMenulink.textContent,
    eventTargetUrl: undefined,
  });
};

on('click', '.menu__tab-link', (event) => {
  const { currentTarget: $target } = event;

  event.preventDefault();

  const $currentTab = $target
    .closest('.menu')
    ?.querySelector<HTMLAnchorElement>('.menu__tab-link[aria-expanded]');

  if ($currentTab && $target !== $currentTab) {
    switchMenu($currentTab, $target);
  }
});

let $activeFocus: HTMLElement | null = null;

on('click', '[data-navigation-link]', (event) => {
  const { currentTarget: $target } = event;
  const { navigationLink = '{}' } = $target.dataset;
  const { label = $target.textContent, level = 0 } = JSON.parse(navigationLink);

  // Get current navigation tab
  const eventSubcategory2 =
    $target.closest('.menu__tab')?.querySelector('.menu__tab-link')
      ?.textContent ?? 'n_a';

  // Is link a tile or a text link?
  const eventLocation = $target.classList.contains('menu__tile')
    ? 'tile_link'
    : 'text_link';

  // Get type of click
  let eventSubcategory = 'clickthrough';
  if ($target.hasAttribute('aria-controls')) {
    eventSubcategory = event.isTrusted ? 'level_down' : 'level_up';
  }

  // Get parent container
  const $parentContainer = $target.closest('.menu__menu-item');

  // Send tracking information
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'nav_menu_interaction',
    eventCategory: getTrackingCategory($target),
    eventSubcategory,
    eventSubcategory2,
    eventSubcategory3: `level_${level}`,
    eventLocation,
    eventInnerLocation: $parentContainer
      ? (elementIndex($parentContainer) + 1).toString()
      : undefined,
    eventLabel: label ?? undefined,
    eventTargetUrl: $target.getAttribute('href') ?? undefined,
  });
});

on('click', '.menu__menu-item [aria-controls]', (event) => {
  const { currentTarget: $target } = event;

  event.preventDefault();

  const controls = $target.getAttribute('aria-controls') as string;
  const $panel = document.getElementById(controls);
  const $menu = $target.closest('.menu__menu');
  const $allMenus = $menu?.closest('.menu');

  if (!$panel || !$menu || !$allMenus) {
    return;
  }

  if ($target.getAttribute('aria-expanded') === 'false') {
    $menu.classList.add('menu__menu--with-open-submenu');
    $target.setAttribute('aria-expanded', 'true');
    $panel.setAttribute('aria-hidden', 'false');

    $activeFocus = $target;
    moveFocus($panel);

    focusable($allMenus).forEach(($focusable) => {
      if (!$panel.contains($focusable)) {
        $focusable.setAttribute('inert', 'inert');
      }
    });
  } else {
    $target.setAttribute('aria-expanded', 'false');
    $panel.setAttribute('aria-hidden', 'true');
    $menu.classList.remove('menu__menu--with-open-submenu');

    if ($activeFocus) {
      moveFocus($activeFocus);
      $activeFocus = null;
    }

    $allMenus.querySelectorAll('[inert]').forEach(($focusable) => {
      $focusable.removeAttribute('inert');
    });
  }
});

on('click', '[data-menu-closes]', (event) => {
  const { currentTarget: $target } = event;

  event.preventDefault();

  $target
    .closest('.menu__menu-item')
    ?.querySelector<HTMLAnchorElement>('[aria-controls]')
    ?.click();
});

on('overlay:show', '[data-overlay-menu]', (event) => {
  const { currentTarget: $overlay } = event;
  const trackingCategory = getTrackingCategory($overlay);

  // Send tracking information
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'nav_menu_interaction',
    eventCategory: trackingCategory,
    eventSubcategory: 'open',
    eventLabel: `${trackingCategory}_open`,
  });

  // Disable scrolling for every menu panel
  $overlay.querySelectorAll('.menu__menu').forEach(($submenu) => {
    disableBodyScroll($submenu);
  });
});

on('overlay:hide', '[data-overlay-menu]', (event) => {
  const { currentTarget: $overlay } = event;

  $overlay.querySelectorAll('.menu__menu').forEach(($submenu) => {
    enableBodyScroll($submenu);
  });
});
